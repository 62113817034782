
.tokyo_custome_container {
    max-width: 1400px;
    position: relative;
    width: 100%;
    margin: 0 auto;
}
.main_button {
    width: 100%;
    height: 50px;
    background-color: #292929;
    -webkit-box-shadow: 0 11px 26px 0 rgb(0 0 0 / 31%);
    box-shadow: 0 11px 26px 0 rgb(0 0 0 / 31%);
    color: #fff;
    font-family: 'ProximaNova-Regular';
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    border: none;
}
.banners>div .s-desc h3 {
    font-size: 32px;
    line-height: 46px;
    margin-bottom: 26px;
    font-family: 'Gill-Sans';
    text-transform: capitalize;
    text-align: center;
    color: #000;
}
.banners.explore_new .banner-box .s-desc {
    position: absolute;
    bottom: 38px;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 0 20px;
}
.banners>div .banner-box {
    position: relative;
}
.banners.explore_new .bimg_box {
    transition: transform 0.7s ease-in-out, opacity 0.5s ease-out, -webkit-transform 0.7s ease-in-out;
}
.banner-block.banner-1 {
    overflow: hidden;
}
.bannerb_button a {
    height: auto;
    padding: 10px 20px;
    letter-spacing: 0.5px;
}
.banner-box img {
	width: 100%;
	cursor: pointer;
}
.pd0 {
    padding: 0;
}
.banners.explore_new .banner-box .s-desc {
    position: absolute;
    bottom: 38px;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 0 20px;
}
.bannerb_button a {
    height: auto;
    padding: 10px 20px;
    letter-spacing: 0.5px;
}
.banner-block.banner-1:hover .bimg_box {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
}
.main_button:hover {
    color: #f1f1f1;
}
/******************  Best collection style start ****************** */
.product-bestsale-jewelry {
    padding: 45px 0 30px;
    background-size: cover;
    padding-bottom: 0;
    margin-bottom: 0;
    margin-top: 0px;
}
.filter-catelog ul li a img {
    width: 100%;
    height: 100%;
}
.catimg_main {
    width: 100%;
    position: relative;
}
.cateli_main {
	position: relative;
}
.filter-catelog ul {
    margin: 0 auto;
    width: 100%;
    display: inline-flex;
    justify-content: center;
}
.filter-catelog ul li {
    position: relative;
    overflow: visible;
    height: 100%;
    width: 100%;
    text-align: center;
}
.filter-catelog {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 25px;
    padding-top: 15px;
    margin-bottom: 15px;
}
.title-box2 {
    margin-bottom: 25px;
}
.product-bestsale-jewelry .title-box2 h2 {
    margin-bottom: 20px;
}
.title-box2 h2 {
    margin-bottom: 40px;
    color: var(--darklimeGreen);
}
.main_heading h2 {
    font-family: 'Proxima-Nova-Bold';
    text-transform: uppercase;
}
.cateli_main p {
	font-size: 26px;
	/* padding: 0 40px 60px; */
	line-height: 1.45;
	z-index: 2;
	position: absolute;
	bottom: 20px;
	color: #222;
	text-align: center;
	left: 0;
	right: 0;
	font-weight: bold;
	letter-spacing: 1.5px;
	font-family: 'Gill-Sans';
	margin: 0 0 10px;
    cursor: pointer;
}
.cateli_main p.white {
    color: #fff;
}
.filter-catelog ul li a:hover {
    transition: all .4s;
    transform: scale(1.08) !important;
}
.cateli_main a {
    display: block;
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
    box-shadow: 0 20px 20px 0px rgb(0 0 0 / 9%);
}
.g_best_collection_slider .slick-slide:nth-child(1) .cateli_main p, .g_best_collection_slider .slick-slide:nth-child(3) .cateli_main p{
color: #fff;
}

/* shop by shap style */
.t_shop_by_shap_Section ul li {
	display: inline-block !important;
	width: 10% !important;
	text-align: center;
	margin-bottom: 10px;
}

.t_shap_imgg_boxx_With_name img {
    width: 70px;
    height: 70px;
    margin: 0 auto;
    margin-bottom: 15px;
	opacity: .6;
}
.t_shap_imgg_boxx_With_name h3 {
	font-size: 15px;
	text-transform: uppercase;
	position: relative;
	padding-top: 10px;
	color: #555;
	font-family: 'Proxima-Nova-Bold';
	font-weight: 500;
	text-align: center;
}
.t_shap_imgg_boxx_With_name h3::before {
    position: absolute;
    width: 30px;
    height: 1px;
    content: "";
    background: #333;
    top: 0;
    left: 50%;
    margin: 0 -15px;
}
.t_shap_imgg_boxx_With_name:hover img{
opacity: 1;
}
.t_shap_imgg_boxx_With_name:hover h3 {
    color: #000;
}
.t_shop_by_shap_Section {
	padding: 70px 0;
}
/******************  testimonial style start ******************  */
.testimonial-icon1 {
	width: 45px;
	height: 40px;
	background: #637bf7;
	text-align: center;
	font-size: 19px;
	color: #fff;
	line-height: 40px;
	position: absolute;
	top: 33px;
	left: -6px;
	z-index: 99;
}
.testimonial-icon1:before {
    content: "";
    border-bottom: 16px solid #6278ec;
    border-left: 18px solid transparent;
    position: absolute;
    top: -16px;
    left: 0;
}
.testimo-slider2 {
    position: relative;
}

/****************** Diamond Necklaces style start ************************/
.discover_box {
    background: #f7f6fc;
    padding-top: 100px;
}
.img-responsive{
    max-width: 100%;
    height: auto;
}
.discover_boxinner {
    text-align: center;
    position: relative;
    top: -50px;
    width: 100%;
    max-width: 500px;
    margin: 0px auto;
    padding-bottom: 50px;
}
.discover_boxinner h2 {
    font-family: 'Gill-Sans';
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 1px;
}
.main_button {
    width: 100%;
    height: 50px;
    background-color: #292929;
    -webkit-box-shadow: 0 11px 26px 0 rgb(0 0 0 / 31%);
    box-shadow: 0 11px 26px 0 rgb(0 0 0 / 31%);
    color: #fff;
    font-family: ProximaNova-Regular;
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    border: none;
}
.discover_box2 {
    background: #3e6c5529;
}
.discover_boximg21 {
    margin-top: -173px;
    position: relative;
    width: 100%;
    /* left: -50px; */
    /* max-width: 638px; */
    /* overflow: visible; */
    /* padding-right: 0; */
    padding-left: 0;
    max-width: 100% !important;
}
.discover_boximg21 img {
    width: 100%;
}
.discover_boximg21 img {
    margin-top: -100px;
    position: relative;
    width: 100%;
    left: -50px;
}
.discover_boxcontent2 {
    padding: 45px 70px 45px;
    background: #fff;
    position: relative;
    /* right: 50px; */
    z-index: 9;
    padding: 0;
}
.discover_boxcontentin2 {
    padding: 50px;
    position: relative;
    right: 96px;
    background: #fff;
    text-align: center;
    margin-top: 123px;
}
.beauty_content h2 {
    font-size: 36px;
    font-family: 'Gill-Sans';
    font-weight: bold;
    width: 100%;
    max-width: 350px;
    margin: 0px auto;
    margin-bottom: 25px;
}
.discover_btn a {
    color: #fff;
    text-transform: capitalize;
    margin-top: 20px;
    height: auto;
    padding: 10px 20px;
    display: block;
    width: 100%;
    max-width: 250px;
    margin: 0px auto;
    font-size: 16px;
}
.beauty_content p {
    width: 100%;
    max-width: 615px;
    margin: 0px auto;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 16px;
}
.discover_box {
	background: #3e6c5529;
	padding-top: 100px;
}
.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
}
.discover_boximg21 img {
    width: 100%;
}
/****************** Diamond Necklaces style end ************************/
/****************** Loose Diamond style ************************/
.natursection{
background-image: url('../../Containers/Front/tokyo/images/home-banner-natural.png');
width: 100%;
    text-align: center;
    position: relative;
    color: #fff;
    width: 100%;
    height: 450px;
    background-size: cover !important;
    background-position: center !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-attachment: fixed !important;
    margin-bottom: 45px;
}
.naturessection-box h2 {
    font-size: 50px;
    color: #fff;
    line-height: 1.2;
    margin-bottom: 0.25em;
    text-transform: capitalize;
}
.naturessection-box p {
    font-size: 16px;
    width: 100%;
    max-width: 390px;
    margin: 0px auto;
    letter-spacing: 1px;
    margin-bottom: 20px;
}
.naturessection-box a {
    display: block;
    max-width: 160px;
    margin: 0px auto;
    height: auto;
    padding: 10px 10px;
    border: 1px solid #f1f1f1;
}
.main_button {
    width: 100%;
    height: 50px;
    background-color: var(--darklimeGreen);
    -webkit-box-shadow: 0 11px 26px 0 rgb(0 0 0 / 31%);
    box-shadow: 0 11px 26px 0 rgb(0 0 0 / 31%);
    color: #fff;
    font-family: 'ProximaNova-Regular';
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    border: none;
}

.insta-section .subtitle {
	font-size: 16px;
	line-height: 25px;
	padding-top: 10px;
}

/* Responsive style */

@media (max-width: 767px){
    .discover_box {
        padding-top: 15px;
    }
    .discover_boxinner {
        position: relative;
        top: 10px;
        width: 100%;
        max-width: unset;
        padding-bottom: 50px;
    }
    .discover_boxinner h2 {
        font-size: 20px;
        line-height: 28px;
    }
    .discover_boximg21 {
        margin-top: 0;
    }
    .discover_boximg21 img {
        margin-top: 0;
        position: relative;
        width: 100%;
        left: unset;
    }
    .discover_boxcontentin2 {
        padding: 15px;
        position: relative;
        right: unset;
        background: #fff;
        text-align: center;
        margin-top: 0;
    }
}


@media(min-width:320px) and (max-width:767px){
    .catimg_main img {
        width: 100%;
        height: auto;
    }
  
    .banner-block.banner-1:hover .bimg_box {
        transform: none;
    }
    .banners.explore_new .banner-box .s-desc {
        bottom: 36px;
    }
    .banners > div .s-desc h3 {
        font-size: 25px;
        line-height: 35px;
        margin-bottom: 22px;
    }
    .tokyo_custome_container {
        max-width: 100%;
        position: relative;
        width: 100%;
        margin: 0 auto;
    }
    .beauty_content h2 {
        font-size: 30px;
        margin-bottom: 15px;
    }
    .beauty_content p {
        width: 100%;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 16px;
    }
    .main_heading h2 {
        font-size: 25px;
        line-height: 30px;
        margin-bottom: 15px;
    }
    .t_shap_imgg_boxx_With_name img {
        width: 60px;
        height: 60px;
    }
    .t_shop_by_shap_Section {
        padding: 40px 0;
    }
    .naturessection-box h2 {
        font-size: 35px;
    }
    .natursection {
        height: 300px;
    }
    .insta-section .subtitle {
        font-size: 16px;
        line-height: 25px;
        padding-top: 10px;
        margin-bottom: 20px !important;
    }
    .main-footer2 .footer-image_d {
        width: 110px !important;
        position: absolute;
        right: 5% !important;
        top: -87px !important;
        z-index: 100;
    }

}
@media(min-width:768px) and (max-width:991px){
    .discover_boxinner {
        top: 0;
        padding-bottom: 30px;
        padding-top: 30px;
    }
    .discover_boximg21 img {
        width: 100%;
        margin: 0 auto;
        left: 0;
    }
    .discover_box {
        padding-top: 40px;
    }
    .discover_boxcontentin2 {
        padding: 0;
        right: 0;
        margin-top: 40px;
    }
    .img-responsive {
        justify-content: center;
        margin: 0 auto;
    }
    .tokyo_custome_container {
        max-width: 100%;
        position: relative;
        width: 100%;
        margin: 0 auto;
    }
    .banners > div .s-desc h3 {
        font-size: 25px;
        line-height: 35px;
        margin-bottom: 25px;
    }
    .banners.explore_new .banner-box .s-desc {
        bottom: 20px;
    }
    .cateli_main p {
        font-size: 22px;
        line-height: 25px;
    }
    .main_heading h2 {
        font-size: 25px;
        margin-bottom: 20px;
        line-height: 35px;
    }
    .natursection {
        height: 350px;
        margin-bottom: 10px;
    }
    .naturessection-box h2 {
        font-size: 35px;
    }
    .insta-section .subtitle {
        font-size: 16px;
        line-height: 25px;
        padding-top: 10px;
    }
    .insta-section .section-space {
        padding: 30px 0;
    }
    .insta-iframe {
        height: 170px !important;
    }
}
@media(min-width:992px) and (max-width:1024px){

}
@media(min-width:1025px) and (max-width:1149px){
    .tokyo_custome_container {
        max-width: 1150px !important;
        position: relative;
        width: 100%;
        margin: 0 auto;
    }
}
@media(min-width:1200px) and (max-width:1440px){
    .banner-box img {
        width: 100%;
        height: 520px;
        /* object-fit: cover; */
    }
    .banners.explore_new .banner-box .s-desc {
        bottom: 28px;
    }
    .natursection {
        height: 350px;
    }
    .tokyo_custome_container {
        max-width: 1150px !important;
        position: relative;
        width: 100%;
        margin: 0 auto;
    }
    .t_shap_imgg_boxx_With_name img {
        width: 60px;
        height: 60px;
    }
    .cateli_main p {
        font-size: 20px;
        margin: 0 0 10px;
    }
    .banners > div .s-desc h3 {
        font-size: 25px;
        line-height: 30px;
        margin-bottom: 25px;
    }
    .beauty_content h2 {
        font-size: 28px;
        margin-bottom: 15px;
    }
    .beauty_content p {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 16px;
    }
    .t_shap_imgg_boxx_With_name h3 {
        font-size: 14px;
    }
    .rcs_testi_item p {
        font-size: 14px;
        margin-bottom: 10px;
    }
    .rcs_test_star svg {
        font-size: 15px;
    }
    img.google_rev_img {
        width: 90px;
    }
    .naturessection-box p {
        font-size: 14px;
        line-height: 20px;
    }
    .tab-content.clearfix h5 {
        font-size: 14px;
        padding-top: 10px;
    }
    .bannerb_button a {
        padding: 8px 18px;
        font-size: 14px;
    }
    .discover_btn a {
        padding: 8px 18px;
        max-width: 220px;
        font-size: 14px;
    }
    .discover_boxinner h2 {
        font-size: 25px;
    }
    .naturessection-box a {
        max-width: 150px;
        padding: 8px 18px;
        font-size: 14px;
    }
    .insta-section.section-space {
        padding-top: 0;
    }
    .naturessection-box h2 {
        font-size: 30px;
        line-height: 35px;
    }
    .main_heading h2 {
        font-size: 30px;
        line-height: 35px;
        margin-bottom: 15px;
    }
}
@media(min-width:1441px) and (max-width:1900px){
    .tokyo_custome_container {
        max-width: 1150px !important;
        position: relative;
        width: 100%;
        margin: 0 auto;
    }
}
